import { MemberTableSubject } from "@components/common/faro-table/faro-table-types";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";

/** All the possible headers for members table */
export enum MemberHeaders {
  /** User avatar */
  avatar = "Avatar",

  /** User full name */
  user = "User",

  /** User full name or team name */
  name = "Name",

  /** User email */
  email = "Email",

  /** User status */
  status = "Status",

  /** User role */
  role = "Role",

  /** Date in which a member joined a group */
  joinedDate = "Joined date",

  /** Any other possible options */
  options = "Options",
}

/** Returns the specific role column header according to the table context type */
export function getRoleHeader(
  tableSubject: MemberTableSubject | undefined
): string {
  switch (tableSubject) {
    case "workspace":
      return "Workspace Role";

    case "project":
      return "Project Role";

    default:
      return "Role";
  }
}

/** The display name to show representing memberTableSubject */
export function getMemberTableSubjectDisplayName(
  memberTableSubject: MemberTableSubject
): string {
  switch (memberTableSubject) {
    case "project":
    case "group":
    case "workspace":
    case "snapshot":
      return memberTableSubject;

    case "team":
      return TEAM_DISPLAY_NAME;
  }
}
